<template>
  <vs-card>
    <h3 class="mb-4">
      Novo Grupo de Sorteio
    </h3>

    <div class="d-flex flex-column flex-nowrap align-items-start">
      <form>
        <!-- General -->
        <vs-row>
          <vs-col
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
          >
            <vs-col
              vs-lg="6"
              vs-sm="12"
              vs-xs="12"
            >
              <vs-input
                v-model="prizeName"
                class="mb-4 w-100"
                icon-after="true"
                icon="mode_edit"
                label="Nome do Projeto*"
                placeholder="Digite o nome do projeto"
              />
            </vs-col>

            <vs-divider class="mt-3" />

            <vs-col
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >
              <!-- Registration -->
              <vs-card class="mt-2">
                <div
                  slot="header"
                  class="d-flex align-items-center"
                >
                  <vs-checkbox
                    v-model="signToParticipate"
                    :color="colorSecondary"
                  >
                    <h5>Inscrições</h5>
                  </vs-checkbox>
                </div>

                <vs-row>
                  <!-- Survey -->
                  <PrizeSurveyUrlForm
                    :sign-to-participate="signToParticipate"
                    :survey-id="surveyId"
                    :url-survey-id="urlSurveyId"
                    @set-url-survey-id="(val) => (urlSurveyId = val)"
                  />
                </vs-row>
              </vs-card>
            </vs-col>
          </vs-col>

          <vs-col
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
          >
            <vs-row>
              <!-- Filter -->
              <vs-col
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                class="ml-3"
              >
                <vs-divider position="left">
                  <div class="d-flex align-items-center">
                    Selecione o filtro dos participantes:

                    <vs-tooltip
                      delay="0.3s"
                      position="top"
                      text="Escolha o filtro que conterá a lista de participantes desse sorteio."
                    >
                      <i
                        class="mdi mdi-information-outline ml-2"
                        :style="colorSecondary"
                      />
                    </vs-tooltip>
                  </div>
                </vs-divider>

                <div class="d-flex">
                  <div class="col-7 d-flex flex-column justify-content-start">
                    <vs-chip class="mb-3 py-2 rounded-lg c-draw-vs-chip">
                      <h6>{{ filterName ? filterName : 'Nenhum filtro selecionado' }}</h6>

                      <span v-if="filterId && filterIdCount">
                        |
                      </span>

                      <div
                        v-if="filterId"
                        class="d-flex"
                      >
                        <i class="fa fa-user mr-1" />

                        {{ filterIdCount }}
                      </div>
                    </vs-chip>

                    <vs-button @click="openFiltersPopup">
                      <i class="fa fa-bars mr-1" />

                      Selecionar Filtro
                    </vs-button>

                    <!-- Select Filter Popup -->
                    <vs-popup
                      id="draw-select-filter"
                      :active.sync="selectFilterPopup"
                      title="Meus Filtros"
                    >
                      <vs-table
                        :data="filters"
                        max-height="525px"
                        :max-items="10"
                        pagination
                        :search="search"
                        stripe
                      >
                        <template slot="thead">
                          <vs-th sort-key="id">
                            Id
                          </vs-th>

                          <vs-th sort-key="contactname">
                            Nome
                          </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                          <vs-tr
                            v-for="(filter, filterIdx) in data"
                            :key="filterIdx"
                          >
                            <vs-td :data="filter.filterId">
                              <h5
                                class="mb-0 font-16 cursor-pointer"
                                @click="selectFilter(filter)"
                              >
                                {{ filter.filterId }}
                              </h5>
                            </vs-td>

                            <vs-td :data="filter.name">
                              <h5
                                class="mb-0 font-16 cursor-pointer"
                                @click="selectFilter(filter)"
                              >
                                {{ filter.name }}
                              </h5>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </vs-popup>

                    <div
                      class="mt-4"
                      :style="{ color: colorSecondary }"
                    >
                      <a
                        class="c-draw-link"
                        @click="goToFilter"
                      >
                        Ainda não tem um filtro para esse sorteio?
                      </a>
                    </div>
                  </div>
                </div>
              </vs-col>

              <!-- Notifications -->
              <vs-col
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                class="ml-3 mt-1"
              >
                <vs-divider position="left">
                  Notificações
                </vs-divider>

                <div class="mb-2">
                  <p>Configure os modelos de e-mail e de mensagem de texto que os participantes do sorteio receberão.</p>

                  <PrizeTemplatesForm
                    :announcement-template="announcementTemplate"
                    :confirmation-template="confirmationTemplate"
                    :disabled="false"
                    :invite-template="inviteTemplate"
                    :results-email-template="resultsEmailTemplate"
                    :results-message-template="resultsMessageTemplate"
                    @set-announcement-template="(val) => (announcementTemplate = val)"
                    @set-confirmation-template="(val) => (confirmationTemplate = val)"
                    @set-invite-template="(val) => (inviteTemplate = val)"
                    @set-results-email-template="(val) => (resultsEmailTemplate = val)"
                    @set-results-message-template="(val) => (resultsMessageTemplate = val)"
                  />
                </div>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
      </form>

      <small class="mt-2 mb-3">
        * Indica campos obrigatórios
      </small>

      <vs-button
        :disabled="!validForm"
        size="large"
        @click="createPrizeProject"
      >
        Criar Projeto
      </vs-button>
    </div>
  </vs-card>
</template>

<script>
/* Components */
import PrizeSurveyUrlForm from '../components/PrizeSurveyUrlForm.vue';
import PrizeTemplatesForm from '../components/PrizeTemplatesForm.vue';

/* Directives */
import { mask } from 'vue-the-mask';

/* Services */
import Fan from '@/services/fan';
import Filter from '@/services/filter';
import Prize from '@/services/prize';
import Survey from '@/services/survey';

/* Validations */
import validatePrizeProject from '../helpers/validate-prize-project';

/* Style */
import '../assets/index.scss';

const fanService = new Fan();
const filterService = new Filter();
const prizeService = new Prize();
const surveyService = new Survey();

export default {
  name: 'PrizeDrawView',
  components: {
    PrizeSurveyUrlForm,
    PrizeTemplatesForm,
  },
  directives: { mask },
  data: () => ({
    /* General */
    prizeName: '',
    /* Survey */
    urlSurveyId: 0,
    surveyId: 0,
    signToParticipate: false,
    /* Templates */
    announcementTemplate: 0,
    confirmationTemplate: 0,
    inviteTemplate: 0,
    resultsEmailTemplate: 0,
    resultsMessageTemplate: 0,
    /* Filter */
    filters: [],
    search: '',
    filterName: '',
    filterId: 0,
    filterIdCount: 0,
    selectFilterPopup: false,
    /* Other */
    saveFormProgress: false,
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    projectId() {
      return parseInt(this.$store.state.selectedProject);
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    /* Submit Form */
    prizePayload() {
      let payload = {
        ownerId: this.ownerId,
        prizeName: this.prizeName,
        signToParticipate: this.signToParticipate,
        projectId: this.projectId,
        filterId: this.filterId,
        inviteTemplate: this.inviteTemplate,
        confirmationTemplate: this.confirmationTemplate,
        announcementTemplate: this.announcementTemplate,
        resultsMessageTemplate: this.resultsMessageTemplate,
        resultsEmailTemplate: this.resultsEmailTemplate,
        surveyUrl: '',
        surveyResultUrl: '',
      };

      return payload;
    },
    validForm() {
      return validatePrizeProject(this.$data);
    },
  },
  created() {
    this.getFilters();
  },
  mounted() {
    const savedInfo = this.$store.state.prizeDrawInfo;

    if (savedInfo && Object.keys(savedInfo).length > 0) {
      this.setForm(savedInfo);
    }
  },
  beforeDestroy() {
    if (this.saveFormProgress) {
      this.$store.commit('SET_prizeDrawInfo', this.$data);
    } else {
      this.$store.commit('SET_prizeDrawInfo', {});
    }
  },
  methods: {
    /* API */
    createPrizeProject() {
      this.openCreateDrawDialog();

      prizeService.createPrizeProject(
        this.prizePayload,
        this.token,
      )
        .then((resp) => {
          if (this.signToParticipate && this.urlSurveyId) {
            this.updateSurveyLink(this.urlSurveyId, resp.surveyId);
          }

          this.openCreateDrawDialog();
        })
        .catch((err) => this.errorAlert(err));
    },
    updateSurveyLink(oldSurvey, newSurvey) {
      const payload = {
        oldSurveyId: oldSurvey,
        newSurveyId: newSurvey,
      };

      surveyService.updateLink(payload, this.token)
        .catch((err) => this.errorAlert(err));
    },
    getFilters() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService.getFilters(this.ownerId)
        .then((resp) => {
          this.filters = resp;

          if (this.filterId !== 0 && !this.filterName.length) {
            const filter = this.filters.find((f) => f.filterId === this.filterId);
            if (filter) this.filterName = filter.name;
          }
        })
        .catch((err) => (this.errorAlert(err)))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getIdCount(filterId) {
      this.$store.dispatch('set_isLoadingActive', true);

      fanService.getIdsRegisterCount(
        this.projectId,
        filterId,
      )
        .then((ret) => (this.filterIdCount = parseInt(ret)))
        .catch((err) => (this.errorAlert(err)))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.closeFiltersPopup();
        });
    },
    /* Helpers */
    selectFilter(filter) {
      this.filterName = filter.name;

      this.filterId = filter.filterId;
      this.prizeQty = 0;

      this.getIdCount(filter.filterId);

      this.$vs.notify({
        title: 'Selecionado',
        text: `Filtro: ${filter.name}`,
      });
    },
    /* Alerts */
    errorAlert(err) {
      console.error(err);

      this.$vs.notify({
        color: 'danger',
        title: 'Erro',
        text: err,
      });
    },
    /* Popups */
    closeFiltersPopup() {
      this.selectFilterPopup = false;
    },
    openFiltersPopup() {
      this.selectFilterPopup = true;
    },
    openCreateDrawDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Grupo de sorteio criado com sucesso!',
        text: 'Deseja criar também o primeiro sorteio para esse grupo?',
        cancelText: 'Por enquanto não',
        acceptText: 'Continuar criando',
        accept: this.goToCreateDraw,
        cancel: this.goToDraws,
      });
    },
    /* Router */
    goToCreateDraw() {
      this.$router.push('/sorteios/criar');
    },
    goToDraws() {
      this.$router.push('/sorteios/listar');
    },
    goToFilter() {
      this.saveFormProgress = true;

      this.$router.push({
        name: 'Filtros',
        params: { returnLink: '/sorteios/criar' },
      });
    },
  },
};
</script>

<style lang="scss">
/* Custom Draw Components */
.c-draw-link {
  color: black !important;
  font-size: 0.95em;
  text-decoration: underline !important;
}

.c-draw-link:hover {
  color: inherit !important;
  cursor: pointer !important;
}

.c-draw-number-input {
  margin: 0 7px;

  .vs-input--input {
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.c-draw-vs-chip {
  display: flex;
  justify-content: stretch;

  .text-chip.vs-chip--text {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    width: 100%;
  }
}
</style>
